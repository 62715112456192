<template>
    <div class="classroomTeaching">
        <div class="banner-classroomTeaching">
            <div  class="wrap1148 wrapTop">
                <p class="wrapTit">课堂教学</p>
                <el-carousel :interval="4000" type="card" height="320px" width="580px">
                    <el-carousel-item v-for="item in bannerArr" :key="item.bg">
                        <h3 class="medium" :style="item.bgInfo"></h3>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="c-txt-teachinGood u-center">
            <div class="wrap1200">
                <p class="tit-tips">
                    适用设备 <span class="tips-blue">班班通</span>
                    <span class="tips-blue">一体机</span>
                    <span class="tips-blue">个人PC</span>
                </p>
                <p class="txt-tips">
                    为教师的课堂教学赋能，搭建智慧课堂。同步教材，覆盖教学、练习、评测等教育场景，<br>
                    通过大数据和人工智能等教育科技手段，帮助老师减负增效、学生培养学习兴趣、家长<br>
                    掌握学情，让学习成为美好体验。
                </p>
            </div>
        </div>
        <div class="wrap1200 help-links">
            <ul class="help-list">
                <li>
                    <h6>小学英语</h6>
                    <a href="http://www.eyyb.vip/yyb/index.html" target="_blank" class="tips-blue">进入课堂教学</a>
                    <div class="posra">
                        <video id="englishVideo" muted="muted" src="../../../assets/images/mp4/englishPush.mp4" width="570" height="320" controls="controls">
                            您的浏览器不支持 video 标签。
                        </video>
                    </div>
                    <p>【小学英语教师】使用指南</p>
                </li>
                <li>
                    <h6>小学语文</h6>
                    <a href="https://yw.eyyb.vip/login" target="_blank" class="tips-blue">进入课堂教学</a>
                    <div class="posra">
                        <video id="chineseVideo" muted="muted" src="../../../assets/images/mp4/chinesePush.mp4" width="570" height="320" controls="controls">
                            您的浏览器不支持 video 标签。
                        </video>
                    </div>
                    <p>【小学语文教师】使用指南</p>
                </li>
                
            </ul>
        </div>
        <!-- <ul>
            <li v-for="(item,i) in list" :key="i" style="margin-bottom: 30px">
                <video :id="'myVideo'+item.id" class="video-js">
                    <source :src="item.src" type="video/mp4">
                </video>
            </li>
        </ul> -->
    </div>
</template>

<style lang="scss" scope>
    @import '@/assets/css/pages/classroomTeaching';
</style>

<script>
    import {mapGetters} from "vuex";
    export default {
        data () {
            return {
                bannerArr: [{
                    bgInfo: {
                        background: "url("+require('../../../assets/images/class_01.png')+")"
                    },
                    bg:'#281a10'
                }, {
                    bgInfo: {
                        background: "url("+require('../../../assets/images/class_02.png')+")"
                    },
                    bg:'#e6cfb1'
                }, {
                    bgInfo: {
                        background: "url("+require('../../../assets/images/class_03.png')+")"
                    },
                    bg:'#413366'
                }],
                list:[
                    {
                        src:'https://www.w3school.com.cn/i/movie.ogg ',
                        id:0,
                        pic:"",
                    },
                    {
                        src:'https://www.w3school.com.cn/i/movie.ogg ',
                        id:2,
                        pic:"",
                    }
                ]
            }
        },
        mounted() {
            // this.initVideo();
        },
        mounted() {
            var englishVideo = document.getElementById('englishVideo');
            var chineseVideo = document.getElementById('chineseVideo');
            var eventTester01 = function(e){
                englishVideo.addEventListener(e,function(){
                    chineseVideo.pause();
                },false);
            }
            var eventTester02 = function(e){
                chineseVideo.addEventListener(e,function(){
                    englishVideo.pause();
                },false);
            }
            eventTester01("play");
            eventTester02("play");
        },
        methods: {
            initVideo() {
                //初始化视频方法 循环列表获取每个视频的id
                this.list.map((item,i)=>{
                    let myPlayer = this.$video('myVideo'+item.id, {
                        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
                        controls: true,
                        //自动播放属性,muted:静音播放
                        autoplay: "muted",
                        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
                        preload: "auto",
                        //设置视频播放器的显示宽度（以像素为单位）
                        width: "800px",
                        //设置视频播放器的显示高度（以像素为单位）
                        height: "400px",
                        //封面图
                        poster:item.pic
                    });
                })

            }
        }
    }
</script>

